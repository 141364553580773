import { Component, Input } from '@angular/core';

@Component({
  selector: 'story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.css'],
  // animations: [
  //   // animation triggers go here
  // ]
})
export class StoryComponent {
//   title = 'Up North BBQ';

// shake: boolean=false;

// isInViewport =() => {
// if (this.shake === true){
//   return (
//     (this.shake.offets.top >= 0) &&
//     (this.shake.offets.left >= 0) &&
//     (this.shake.offets.bottom <= window.innerHeight) && 
//     (this.shake.offets.right <= window.innerWidth)
//   )
// }
// };

  
}
