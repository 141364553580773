import { Component, Input } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  // animations: [
  //   // animation triggers go here
  // ]
})
export class HomeComponent {
  title = 'Up North BBQ';

//   hover: boolean;


//   constructor(){
//   this.hover=false;

// }
}
